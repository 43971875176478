import Fetchers from 'core/fetchers';

const defaultBtnStyle = { shape: 'rect', height: 48, borderRadius: 12 },
      defaultBtnSelector = '#paypal-button',
      paypalCheckoutPath = '/checkout/paypal',
      abortPaypalOrderPath = '/checkout/abort_paypal_order',
      initializePaypalOrderPath = '/checkout/initialize_paypal_order';

const getCheckoutFormEntries = () => {
  const formEntries = {};
  document
    .querySelector('.m-checkout-2024__form')
    .querySelectorAll('input,select')
    .forEach((field) => {
      if (field.type === 'radio' && !field.checked) return;

      const nestedFieldNames = field.name.match(/(\w+)\[(\w+)\]/);
      if (nestedFieldNames) {
        const parentName = nestedFieldNames[1];
        const childName = nestedFieldNames[2];
        if (!formEntries[parentName]) formEntries[parentName] = {};
        formEntries[parentName][childName] = field.value;
      } else {
        formEntries[field.name] = field.value;
      }
    });
  return formEntries;
};

class PaypalPayment {
  constructor(btnStyle = defaultBtnStyle, btnSelector = defaultBtnSelector) {
    [btnSelector, `${btnSelector}-sidebar`].forEach((buttonId) => this.init(btnStyle, buttonId));
  }

  init(btnStyle, btnSelector) {
    const settings = TeePublic.Checkout.Form;

    // Create a client.
    braintree.client.create({
      authorization: settings.bt_tokenization_key
    }, (clientErr, clientInstance) => {

      // Stop if there was a problem creating the client.
      // This could happen if there is a network error or if the authorization
      // is invalid.
      if (clientErr) {
        console.error('Error creating client:', clientErr);
        return;
      }

      const getDeviceData = (clientInstance) => new Promise((resolve, _reject) => {
        braintree.dataCollector.create(
          { client: clientInstance },
          (_err, dataCollectorInstance) => {
            resolve(JSON.parse(dataCollectorInstance.deviceData))
          },
        );
      });

      // Create a PayPal Checkout component.
      braintree.paypalCheckout.create({
        client: clientInstance
      }, (paypalCheckoutErr, paypalCheckoutInstance) => {
        // Set up PayPal with their JavaScript SDK library
        paypal_2024.Buttons({
          commit: false,
          fundingSource: paypal_2024.FUNDING.PAYPAL,
          style: btnStyle,

          createOrder: () => {
            TeePublic.RudderstackHelpers.trackSubmissionWithController('.jsFormSubmissionPaypal');

            return Fetchers.fetchJSON(
              initializePaypalOrderPath,
              'POST',
              JSON.stringify(getCheckoutFormEntries())
            )
              .then(Fetchers.parseResponse)
              .then((response) => response.data.orderId);
          },

          onApprove: async (data) => {
            const deviceData = await getDeviceData(clientInstance);
            const { details, nonce } =
              await paypalCheckoutInstance.tokenizePayment(data);

            const { email, firstName: first_name, lastName: last_name, billingAddress: { countryCode: country_code } } = details;

            // Submit `payload.nonce` to your server.
            const params = {
              paypal: {
                nonce,
                email,
                billing_address: { first_name, last_name, country_code },
              }
            };

            return Fetchers.fetchJSON(
              paypalCheckoutPath,
              'POST',
              JSON.stringify({ data: params, device_data: deviceData }),
            )
              .then(Fetchers.parseResponse)
              .then((response) => window.location = response.data.redirectUrl);
          },

          onCancel: (data) => {
            console.log('checkout.js payment cancelled', JSON.stringify(data, 0, 2));
            return Fetchers.fetchJSON(abortPaypalOrderPath, 'POST');
          },

          onError: (err) => {
            console.error('checkout.js error', err);
          },
        }).render(btnSelector).then(() => {
          // The PayPal button will be rendered in an html element with the id
          // `paypal-button`. This function will be called when the PayPal button
          // is set up and ready to be used.
        });
      });
    });
  }
}

export default PaypalPayment;
