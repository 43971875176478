import Fetchers from 'core/fetchers';

const baseRequest = { apiVersion: 2, apiVersionMinor: 0 };
const googlePayVersion = 2;
const googlePayTotalPath = '/checkout/google-pay-transaction-info';
const googlePayPaymentPath = '/checkout/google-pay';
const btnSelectors = ['.jsFormSubmissionGooglePayForm', '.jsFormSubmissionGooglePaySidebar'];

class GooglePayPayment {
  constructor() {
    this.googlePaymentsClient = this.createGooglePaymentsClient();
    this.googlePaymentsInstance = null;
    this.deviceData = null;
    this.checkoutFormEntries = null;
    btnSelectors.forEach((btnSelector) => this.init(btnSelector));
  }

  init(btnSelector) {
    this.createBraintreeClient()
      .then((braintreeClientInstance) => this.configureBraintreeClient(braintreeClientInstance))
      .then((googlePaymentsInstance) => this.isReadyToPay(googlePaymentsInstance))
      .then((isReadyToPayResponse) => this.addGooglePayButton(isReadyToPayResponse, btnSelector))
  }

  createGooglePaymentsClient() {
    return new google.payments.api.PaymentsClient({
      environment: TeePublic.Checkout.Form['google_pay_environment'],
    });
  }

  createBraintreeClient() {
    return braintree.client.create({
      authorization: TeePublic.Checkout.Form['bt_tokenization_key']
    });
  }

  configureBraintreeClient(braintreeClientInstance) {
    this.getDeviceData(braintreeClientInstance);
    return this.getBraintreeGooglePayInstance(braintreeClientInstance);
  }

  getDeviceData(braintreeClientInstance) {
    braintree.dataCollector.create(
      { client: braintreeClientInstance },
      (err, dataCollectorInstance) => {
        this.deviceData = dataCollectorInstance.deviceData;
      }
    );
  }

  getBraintreeGooglePayInstance(braintreeClientInstance) {
    return braintree.googlePayment.create({
      client: braintreeClientInstance,
      googlePayVersion: googlePayVersion
    });
  }

  isReadyToPay(googlePaymentsInstance) {
    this.googlePaymentsInstance = googlePaymentsInstance;
    const allowedPaymentMethods = this.googlePaymentsInstance.createPaymentDataRequest().allowedPaymentMethods

    return this.googlePaymentsClient.isReadyToPay({
      ...baseRequest,
      allowedPaymentMethods: allowedPaymentMethods,
      existingPaymentMethodRequired: true
    });
  }

  addGooglePayButton(isReadyToPayResponse, btnSelector) {
    if (isReadyToPayResponse.result !== true) return;

    const button = this.googlePaymentsClient.createButton({
      buttonSizeMode: 'fill',
      buttonType: 'plain',
      buttonRadius: 12,
      buttonColor: 'black',
      onClick: () => this.processCheckout()
    });
    document.querySelector(btnSelector).appendChild(button);
  }

  async processCheckout() {
    TeePublic.RudderstackHelpers.trackSubmissionWithController('.jsFormSubmissionGooglePay');

    const data = await this.getGooglePayTransactionInfo();

    return new Promise((resolve, reject) => {
      if (data['checkoutValid'] === true) {
        const paymentDataRequest = this.createPaymentDataRequest(data);

        this.googlePaymentsClient.loadPaymentData(paymentDataRequest)
          .then((paymentData) => this.processPayment(paymentData))
          .then((result) => this.createOrder(result))
          .then(() => this.loadThankYouPage());
        resolve();
      } else {
        reject(data);
      }
    }).catch((err) => console.warn(err));
  }

  createPaymentDataRequest(data) {
    return this.googlePaymentsInstance.createPaymentDataRequest({
      merchantInfo: {
        merchantName: data['merchantName'],
        merchantId: data['merchantId']
      },
      transactionInfo: {
        currencyCode: data['currencyCode'],
        totalPriceStatus: data['totalPriceStatus'],
        totalPrice: data['totalPrice'],
        checkoutOption: data['checkoutOption']
      }
    });
  }

  processPayment(paymentData) {
    return this.googlePaymentsInstance.parseResponse(paymentData);
  }

  createOrder(result) {
    const form = document.querySelector('.m-checkout-2024__form');
    const deviceDataInput = document.createElement('input');

    deviceDataInput.name = 'device_data';
    deviceDataInput.type = 'hidden';
    form.appendChild(deviceDataInput);
    deviceDataInput.value = this.deviceData;

    document.querySelector('#nonce').value = result.nonce;
    document.querySelector('#checkout_form').submit();
  }

  loadThankYouPage() {
    TeePublic.Components.Utilities.loader('.jsThankyouPageLoader').toggle();
  }

  getGooglePayTransactionInfo() {
    return Fetchers.fetchJSON(googlePayTotalPath, 'POST', JSON.stringify(this.getCheckoutFormEntries()))
      .then(Fetchers.parseResponse)
      .then(response => response.data)
  }

  getCheckoutFormEntries() {
    if (this.checkoutFormEntries === null) {
      this.checkoutFormEntries = this.serializeCheckoutFormEntries();
    }
    return this.checkoutFormEntries;
  }

  stringifyNestedCheckoutFormEntries() {
    const entries = {};
    for (const [key, value] of Object.entries(this.getCheckoutFormEntries())) {
      if (typeof value === 'object') {
        entries[key] = JSON.stringify(value);
      } else {
        entries[key] = value;
      }
    }
    return entries;
  }

  serializeCheckoutFormEntries() {
    const formEntries = {};
    document.querySelector('.m-checkout-2024__form').querySelectorAll('input,select').forEach((field) => {
      if (field.type === 'radio' && !field.checked) return;

      const nestedFieldNames = field.name.match(/(\w+)\[(\w+)\]/);
      if (nestedFieldNames) {
        const parentName = nestedFieldNames[1];
        const childName = nestedFieldNames[2];
        if (!formEntries[parentName]) formEntries[parentName] = {};
        formEntries[parentName][childName] = field.value;
      } else {
        formEntries[field.name] = field.value;
      }
    });
    return formEntries;
  }
}

export default GooglePayPayment;
