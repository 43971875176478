const validCollapsibleSections = ['contact-info', 'shipping-address', 'shipping-method']

class CheckoutCollapse {
  fieldsetForm(name) {
    if (this.invalidSectionName(name)) return this.invalidSectionMessage()
    if (this.invalidFieldset(name)) return 'Section has 1 or more invalid entries'

    document.querySelector(`fieldset[name="${name}"]`).children[0].classList.add('hide')
    document.querySelector(`.m-checkout__collapsed-${name}`).classList.remove('hide')
  }

  lockedText(name) {
    if (this.invalidSectionName(name)) return this.invalidSectionMessage()

    document.querySelector(`.m-checkout__collapsed-${name}`).classList.add('hide')
    document.querySelector(`fieldset[name="${name}"]`).children[0].classList.remove('hide')
  }

  invalidFieldset(name) {
    const fieldset = document.querySelector(`fieldset[name="${name}"]`)
    return fieldset.attributes['data-checkout--validity--fieldset-valid-value'].value === 'false'
  }

  invalidSectionName(name) {
    return validCollapsibleSections.includes(name) === false
  }

  invalidSectionMessage() {
    return `Invalid section name. Valid selections are: ${validCollapsibleSections.join(', ')}`
  }
}

export default CheckoutCollapse;
