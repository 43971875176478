import CheckoutCollapse from './checkout_collapse';
import BraintreeJS from './braintree';
import GooglePayJS from './google_pay_payment';
import PaypalJS from './paypal_payment';

const Collapse = new CheckoutCollapse();
const Braintree = () => {
  new BraintreeJS();
}
const GooglePayPayment = () => {
  new GooglePayJS();
}
const PaypalPayment = () => {
  new PaypalJS();
}
const Checkout = {
  Braintree,
  Collapse,
  GooglePayPayment,
  PaypalPayment,
};

export default Checkout;
