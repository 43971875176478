/* Core */
import 'core/controllers'
import { TeePublic } from 'core/globals'
import initRudderstackCheckoutTracker from 'core/services/rudderstack_checkout_tracker';
import RudderstackHelpers from 'core/services/rudderstack_helpers';
import initProductClicks from 'core/services/rudderstack'

/* Shared */
import 'shared/autocomplete'
import Stores from 'shared/stores'
import Validators from 'shared/validators'
import FormsNew from 'shared/legacy_js/forms_new'
import initUpdateSaleTimes from 'shared/legacy_js/home'
import EmailHelpers from 'shared/helpers/email_helpers'
import mtrlModals from 'shared/modals/mtrl_modals'
import ProductHelper from 'shared/helpers/ProductHelper'
import initExploreNav from 'shared/navigation/explore_nav'

/* Packs Contents */
import ArtistTools from 'packs_contents/application/artist'
import LandingSlider from 'packs_contents/application/landing/slider';
import ProductPage from 'packs_contents/product_page'
import Checkout from 'packs_contents/checkout'

TeePublic.ArtistTools = ArtistTools;
TeePublic.EmailHelpers = EmailHelpers;
TeePublic.FormsNew = FormsNew;
TeePublic.LandingSlider = LandingSlider;
TeePublic.ProductPage = ProductPage;
TeePublic.ProductHelper = ProductHelper;
TeePublic.Stores = Stores;
TeePublic.Validators = Validators;
TeePublic.initRudderstackCheckoutTracker = initRudderstackCheckoutTracker;
TeePublic.RudderstackHelpers = RudderstackHelpers;
TeePublic.initProductClicks = initProductClicks;
TeePublic.Checkout2024 = Checkout;