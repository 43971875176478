class Braintree {
  constructor() {
    this.form = document.querySelector('.jsCheckoutForm2024')
    this.init();
  }

  static fieldMapping() {
    return {
      number: { selector: '.jsBraintreeField_number' },
      cvv: { selector: '.jsBraintreeField_cvv' },
      expirationDate: { selector: '.jsBraintreeField_expirationDate' },
    }
  }

  init() {
    this.setupBraintree()
  }

  setupBraintree() {
    braintree.client.create(
      { authorization: TeePublic.Checkout.Form.bt_tokenization_key },
      (clientErr, clientInstance) => {
        braintree.dataCollector.create(
          { client: clientInstance },
          (err, dataCollectorInstance) => {
            if (err) return;
            var deviceDataInput = this.form['device_data'];

            if (deviceDataInput == null) {
              deviceDataInput = document.createElement('input');
              deviceDataInput.name = 'device_data';
              deviceDataInput.type = 'hidden';
              this.form.appendChild(deviceDataInput);
            }
            deviceDataInput.value = dataCollectorInstance.deviceData;
          }
        );

        // CREDIT CARD CONFIGURATION
        braintree.hostedFields.create(
          {
            client: clientInstance,
            fields: Braintree.fieldMapping(),
            styles: {
              'input': {
                'font-size': '16px',
                'color': '#151523',
                'padding-left': '16px',
              }
            },
          },

          (hostedFieldsErr, hostedFieldsInstance) => {
            //TeePublic.Checkout.Form.submit.removeAttribute('disabled');

            // CARD HIGHLIGHTING
            hostedFieldsInstance.on('cardTypeChange', function (event) {
              if (event.cards.length === 1) { Braintree.dimInactiveCreditCards(event.cards) }
            });

            hostedFieldsInstance.on('validityChange', (event) => {
              Braintree.handleValidityChange(hostedFieldsInstance)
            });

            hostedFieldsInstance.on('blur', (event) => {
              Braintree.markInputAsBlurred(event.emittedBy)
              Braintree.handleValidityChange(hostedFieldsInstance)
            });

            var ccButton = document.querySelector('.jsCCSubmitButton')
            var ccSidebarButton = document.querySelector('.jsCCSidebarSubmitButton')

            ccButton.addEventListener('click', Braintree.handleBraintreeFormSubmit);
            ccSidebarButton.addEventListener('click', Braintree.handleSibebarSubmit);
            ccButton.hostedFieldsInstance = ccSidebarButton.hostedFieldsInstance = hostedFieldsInstance;
          }
        )
      }
    )
  }

  static dimInactiveCreditCards(cardData) {
    document.querySelectorAll('.jsCCImages').forEach((image) => {
      image.classList.add('dimmed');
    });
    document.querySelector(`.jsCC${cardData[0].type}`).classList.remove('dimmed')
  }

  static handleValidityChange(hostedFieldsInstance, forceValidation) {
    const state = hostedFieldsInstance.getState();
    const validClass = '.tp-input-field--text-success';
    const invalidClass = 'tp-input-field--text-error';

    Object.entries(state.fields).forEach((entry) => {
      const [key, validity] = entry;
      if (forceValidation) { Braintree.markInputAsBlurred(key) }

      const wrapper = document.querySelector(`.jsBraintreeWrapper_${key}`);
      wrapper.classList.remove(validClass, invalidClass);
      const inputContainer = document.querySelector(`.jsBraintreeField_${key}`);
      inputContainer.classList.remove('braintree-hosted-fields-invalid')

      if (validity.isValid) {
        wrapper.classList.add(validClass)
      } else if (
        (validity.isValid === false && validity.isPotentiallyValid === false)
        || (validity.isValid === false && wrapper.dataset.blurred === 'true')
      ) {
        wrapper.classList.add(invalidClass)
        inputContainer.classList.add('braintree-hosted-fields-invalid')
      }
    });
  }

  static handleSibebarSubmit(event) {
    const hostedFieldsInstance = event.currentTarget.hostedFieldsInstance
    Braintree.handleValidityChange(hostedFieldsInstance, true)
    if (!document.querySelector('.m-checkout-2024__form').checkValidity()) return;
    Braintree.handleBraintreeSubmit(event, hostedFieldsInstance);
  }

  static handleBraintreeFormSubmit(event) {
    const hostedFieldsInstance = event.currentTarget.hostedFieldsInstance
    Braintree.handleValidityChange(hostedFieldsInstance, true)
    if (!this.form.checkValidity()) return;
    Braintree.handleBraintreeSubmit(event, hostedFieldsInstance);
  }

  static handleBraintreeSubmit(event, hostedFieldsInstance) {
    event.preventDefault();
    document.querySelector('.jsCCSubmitButton').setAttribute('disabled', true);
    document.querySelector('.jsCCSidebarSubmitButton').setAttribute('disabled', true);

    const valid = Braintree.invalidBraintreeFields(hostedFieldsInstance)
    if (valid) {
      hostedFieldsInstance.tokenize(function (tokenizeErr, payload) {
        if (tokenizeErr) {
          Braintree.handleTokenizationError(tokenizeErr)
        } else {
          document.querySelector('#nonce').value = payload.nonce
          document.querySelector('#checkout_form').submit()
        }
      });
    } else {
      event.preventDefault();
      document.querySelector('.jsCCSubmitButton').removeAttribute('disabled');
      document.querySelector('.jsCCSidebarSubmitButton').removeAttribute('disabled');
      Braintree.focusInvalidBraintreeFields(hostedFieldsInstance)
    }
  }

  static markInputAsBlurred(name) {
    const wrapper = document.querySelector(`.jsBraintreeWrapper_${name}`)
    wrapper.dataset.blurred = true;
  }

  static invalidBraintreeFields(hostedFieldsInstance) {
    const state = hostedFieldsInstance.getState()
    return Object.keys(state.fields).every((key) => {
      return state.fields[key].isValid;
    });
  }

  static focusInvalidBraintreeFields(hostedFieldsInstance) {
    const state = hostedFieldsInstance.getState();

    for (const key of Object.keys(Braintree.fieldMapping())) {
      if (state.fields[key].isValid === false) {
        hostedFieldsInstance.focus(key, (focusErr) => {
          if (focusErr) { console.error(focusErr) }
        });
        break;
      }
    };
  }

  static handleTokenizationError(tokenizeErr) {
    switch (tokenizeErr.code) {
      case 'HOSTED_FIELDS_FIELDS_EMPTY':
        console.error('All fields are empty! Please fill out the form.');
        break;
      case 'HOSTED_FIELDS_FIELDS_INVALID':
        console.error('Some fields are invalid:', tokenizeErr.details.invalidFieldKeys);
        break;
      case 'HOSTED_FIELDS_TOKENIZATION_FAIL_ON_DUPLICATE':
        console.error('This payment method already exists in your vault.');
        break;
      case 'HOSTED_FIELDS_TOKENIZATION_CVV_VERIFICATION_FAILED':
        console.error('CVV did not pass verification');
        break;
      case 'HOSTED_FIELDS_FAILED_TOKENIZATION':
        console.error('Tokenization failed server side. Is the card valid?');
        break;
      case 'HOSTED_FIELDS_TOKENIZATION_NETWORK_ERROR':
        console.error('Network error occurred when tokenizing.');
        break;
      default:
        console.error('Something bad happened! Please contact customer support.', tokenizeErr);
    }
  }
}

export default Braintree;
